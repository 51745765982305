<template>
    <div>
        <!--顶部面包靴-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/institutionalInformation/oldMarketPlayers' }" class="fs20">涉老市场主体</el-breadcrumb-item>
            <el-breadcrumb-item class="fs20">{{ '查看' }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="contentDetail">
            <el-descriptions title="基本信息" colon :column="2" size="">
                <el-descriptions-item label="名称">{{ info.name }}</el-descriptions-item>
                <el-descriptions-item label="统一社会信用代码">{{ info.socialCode }}</el-descriptions-item>
                <el-descriptions-item label="住所（营业场所）">{{ info.residence }}</el-descriptions-item>
                <el-descriptions-item label="法定代表人">{{ info.legalRepresentative }}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{ info.phone }}</el-descriptions-item>
                <el-descriptions-item label="是否备案">
                    <el-radio-group v-model="info.isKeepOnRecord">
                        <el-radio label="是" :disabled="info.isKeepOnRecord !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="info.isKeepOnRecord !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="涉及备案的经营项目">
                    <el-checkbox-group v-model="checkList" disabled>
                        <el-checkbox v-for="(item, key) in businessItems" :key="key" :label="item">{{ item }}</el-checkbox>
                    </el-checkbox-group>
                </el-descriptions-item>
                <el-descriptions-item label="标签">
                    <el-checkbox-group v-model="checkList1" disabled>
                        <el-checkbox v-for="(item, key) in labelList" :key="key" :label="item">{{ item }}</el-checkbox>
                    </el-checkbox-group>
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>

<script>
import { viewDetailsById, getSomeData } from '@/api/oldMarketPlayers'
export default {
    name: 'oldMarketPlayersDetail',
    data() {
        return {
            checkList: [],
            checkList1: [],
            info: {},
            businessItems: [],
            labelList: []
        }
    },
    mounted() {
        this.getSomeData()
    },
    methods: {
        async getSomeData() {
            const res = await getSomeData()
            this.businessItems = res.data.business_items
            this.labelList = res.data.label
            this.viewDetailsById()
        },
        async viewDetailsById() {
            const params = {
                id: this.$route.query.id
            }
            const res = await viewDetailsById(params)
            this.checkList = res.data.businessItems.split(',')
            if (res.data.label) {
                this.checkList1.push(res.data.label)
            }
            this.info = res.data
        }
    }
}
</script>

<style lang="scss" scoped>
.fs20 {
    font-size: 14px;
}
.contentDetail {
    background-color: #ffffff;
    margin-top: 20px;
    padding: 20px;
}
.myMap {
    width: 100%;
    height: 400px;
}
.el-checkbox {
    box-sizing: border-box;
    margin-right: 0;
    width: 260px;
}
/deep/ .el-descriptions-item__label {
    flex-shrink: 0;
}
/deep/ .el-checkbox-group .is-disabled.is-checked .el-checkbox__inner {
    background-color: #3fcf89 !important;
    border-color: #3fcf89 !important;
}
/deep/ .el-checkbox-group .is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #ffffff !important;
}
/deep/ .el-checkbox-group .is-disabled.is-checked .el-checkbox__label {
    color: #333333 !important;
}
</style>
